/* Global Styles */
body, html {
  margin: 0;
  height: 100%;
  overflow: hidden; /* Prevent scrolling */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Adjust padding and margin for form elements */
form div {
  margin-bottom: 1rem;
}

/* Centered App Background */
.app-background {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 56px); /* Adjust for navbar height */
  padding: 20px; /* Optional padding */
  flex-direction: column;
}

/* Video Background */
.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

/* Form Container */
.app-container {
  position: relative;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  width: 100%;
  max-height: calc(100vh - 56px); /* Adjust for navbar height */
  overflow-y: auto; /* Allow scrolling within the form */
  display: flex;
  flex-direction: column;
  flex: 1;
}

.result {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.calculation-details {
  font-size: 1rem;
  color: #666;
  white-space: pre-wrap;
}

.container, .form-container {
  padding: 20px;
  margin: 0 auto;
}

.results-container, .attribution-container, .disclaimer-container {
  padding: 20px;
  margin: 0 auto;
}

/* Results Container */
.results-container {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  flex-shrink: 0; /* Ensure the results section doesn't shrink */
  width: 100%;
}

/* Attribution Container */
.attribution-container {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  text-align: center;
  font-size: 0.9rem;
}

/* Disclaimer Container */
.disclaimer-container {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  text-align: center;
  font-size: 0.9rem;
  overflow: visible;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .form-container {
    padding: 15px;
    margin: 10px;
  }

  .result {
    font-size: 1.2rem;
  }

  .calculation-details {
    font-size: 0.9rem;
  }

  .attribution-container {
    font-size: 0.8rem;
  }
}

/* Make sure elements fit well on smaller screens */
@media (max-width: 600px) {
  .results-container, .attribution-container, .disclaimer-container {
    padding: 10px;
  }

  .disclaimer-container h2, .attribution-container h2 {
    font-size: 1.2em;
  }

  .disclaimer-container p, .attribution-container p {
    font-size: 0.9em;
  }
}

/* Tooltip Styles */
.react-tooltip {
  max-width: 300px; /* Set a max width for the tooltip */
  white-space: normal; /* Ensure text wraps */
  word-wrap: break-word; /* Break long words */
}

/* Navbar Styles */
.navbar {
  width: 100%;
  background-color: #333;
  overflow: hidden;
  height: 56px; /* Adjust as needed */
  display: flex;
  align-items: center;
}

.navbar a {
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.navbar a:hover {
  background-color: #ddd;
  color: black;
}

/* Ensure buttons and links are visible and accessible */
button, a {
  font-size: 1em;
  text-align: center;
}